import parse from 'utils/parse';

const makeTestId = (...ids: string[]): string => parse.toTestId('cookie-consent-banner', ...ids);

const testId = Object.freeze({
  banner: makeTestId(),
  policyLink: makeTestId('policy-link'),
  accept: makeTestId('accept'),
  reject: makeTestId('reject'),
  manage: makeTestId('manage'),
});

export { testId };
