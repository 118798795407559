import React from 'react';
import Html from 'components/html';
import is from 'utils/is';
import toArray from 'utils/to-array';
import { KeyboardKey, type TestAutomation, type StyledElement } from 'contracts';

import styles from './overlay.module.scss';

interface OverlayElement extends StyledElement<HTMLDivElement>, TestAutomation {
  escapable?: boolean;
  onClose: () => void;
  children: React.ReactNode;
}

const Overlay: React.FunctionComponent<OverlayElement> = (props) => {
  const { testId, escapable, className, onClose, children } = props;

  const handleOnClose = React.useCallback((): void => {
    if (is.func(onClose)) {
      onClose();
    }
  }, [onClose]);

  const handleOnKeyDown = React.useCallback(
    (event: KeyboardEvent): void => {
      if ((event.key as KeyboardKey) === KeyboardKey.Escape) {
        handleOnClose();
      }
    },
    [handleOnClose]
  );

  React.useEffect(() => {
    if (!escapable) return;

    document.addEventListener('keydown', handleOnKeyDown);

    return () => {
      document.removeEventListener('keydown', handleOnKeyDown);
    };
  }, [escapable, handleOnKeyDown]);

  return (
    <Html.div testId={testId} className={[styles.overlay, 'p-10 rounded-1', ...toArray(className)]}>
      {children}
    </Html.div>
  );
};

export type { OverlayElement };
export default Overlay;
