import React from 'react';
import { useIntl } from 'react-intl';
import type { StyledElement, AriaAttributes, TestAutomation } from 'contracts';
import Html from 'components/html';
import Icon, { IconList } from 'components/icon';
import Button from 'components/button';
import Modrawer from 'components/modrawer';
import type { ModrawerElement, ModrawerAPI } from 'components/modrawer';
import Toggle from 'components/toggle';
import Link from 'components/link';
import { noopFn } from 'utils/noop';
import useMobileView from 'enhancers/use-mobile-view';
import useNavigation from 'enhancers/use-navigation';
import type { CookieCategorySelections, CookieConsentCategory } from 'store/cookie-consent';

import Overlay, { type OverlayElement } from './overlay';
import messages from './dialog.i18n';
import { testId } from './dialog.settings';
import styles from './dialog.module.scss';

interface DialogElement extends StyledElement<HTMLDivElement>, AriaAttributes, TestAutomation {
  selections: CookieCategorySelections;
  onToggleClick: (category: CookieConsentCategory, enabled: boolean) => void;
  onConfirmClick: () => void;
  desktopMode?: 'modal' | 'overlay';
  loading?: boolean;
}

enum InfoBox {
  None = -1,
  Essential = 0,
  Analytical = 1,
  Functionality = 2,
}

const Dialog: React.FunctionComponent<DialogElement> = (props) => {
  const { selections, onToggleClick, onConfirmClick, desktopMode = 'modal', loading } = props;
  const { formatMessage } = useIntl();
  const [, , link] = useNavigation();
  const mobile = useMobileView();
  const [selectedInfoBox, setSelectedInfoBox] = React.useState<InfoBox>(InfoBox.None);

  const WrapperComponent = React.useMemo((): React.FunctionComponent<
    (ModrawerElement | OverlayElement) & React.RefAttributes<ModrawerAPI>
  > => {
    if (desktopMode === 'overlay' && !mobile) return Overlay;

    return Modrawer;
  }, [mobile, desktopMode]);

  const toggleInfoBox = React.useCallback((infoBox: InfoBox): void => {
    setSelectedInfoBox((oldVal) => (oldVal !== infoBox ? infoBox : InfoBox.None));
  }, []);

  return (
    <WrapperComponent
      testId={testId.dialog}
      escapable={false}
      maxWidth={460}
      className={[styles.cookieDialog, 'py-0 py-md-10']}
      onClose={noopFn}
    >
      <Html.h4 className={[styles.headerText, 'mt-0 mb-4']} typography="title1" fontWeight="bold">
        {formatMessage(messages.pages.layout.cookieConsentDialog.infoHeader)}
      </Html.h4>
      <Html.p className={[styles.bodyText, 'mt-0 mb-8']} typography="body2">
        {formatMessage(messages.pages.layout.cookieConsentDialog.infoBody, {
          a: (text: React.ReactNode) => (
            <Link href={link.cookies()} target="_blank" variant="primary" testId={testId.policyLink}>
              {text}
            </Link>
          ),
        })}
      </Html.p>
      <Html.h4 className={[styles.headerText, 'mt-0 mb-4']} typography="title1" fontWeight="bold">
        {formatMessage(messages.pages.layout.cookieConsentDialog.controlsHeader)}
      </Html.h4>
      <Html.div className={[styles.bodyText, 'd-flex align-items-center']} typography="body2">
        <Html.span>{formatMessage(messages.pages.layout.cookieConsentDialog.essentialLabel)}</Html.span>
        <Html.span className={[styles.preferenceText, 'ms-auto me-2']}>
          {formatMessage(messages.pages.layout.cookieConsentDialog.essentialStatus)}
        </Html.span>
        <Html.span testId={testId.essentialInfoboxToggle} onClick={() => toggleInfoBox(InfoBox.Essential)}>
          <Icon
            className={[styles.preferenceToggle, selectedInfoBox === InfoBox.Essential && styles.selected]}
            name={IconList.caretDown}
          />
        </Html.span>
      </Html.div>
      <Html.div
        className={[styles.infoBox, selectedInfoBox !== InfoBox.Essential && 'd-none', 'mt-2 p-2']}
        typography="caption"
      >
        {formatMessage(messages.pages.layout.cookieConsentDialog.essentialInfo)}
      </Html.div>
      <Html.div className={[styles.bodyText, 'd-flex align-items-center mt-4']} typography="body2">
        <Html.span>{formatMessage(messages.pages.layout.cookieConsentDialog.analyticalLabel)}</Html.span>
        <Toggle
          testId={testId.analyticalToggle}
          className="ms-auto me-2"
          checked={Boolean(selections.analytical)}
          onChange={(checked) => onToggleClick('analytical', checked)}
          loading={loading}
        />
        <Html.span testId={testId.analyticalInfoboxToggle} onClick={() => toggleInfoBox(InfoBox.Analytical)}>
          <Icon
            className={[styles.preferenceToggle, selectedInfoBox === InfoBox.Analytical && styles.selected]}
            name={IconList.caretDown}
          />
        </Html.span>
      </Html.div>
      <Html.div
        className={[styles.infoBox, selectedInfoBox !== InfoBox.Analytical && 'd-none', 'mt-2 p-2']}
        typography="caption"
      >
        {formatMessage(messages.pages.layout.cookieConsentDialog.analyticalInfo)}
      </Html.div>
      <Html.div className={[styles.bodyText, 'd-flex align-items-center mt-4']} typography="body2">
        <Html.span>{formatMessage(messages.pages.layout.cookieConsentDialog.functionalityLabel)}</Html.span>
        <Toggle
          testId={testId.functionalityToggle}
          className="ms-auto me-2"
          checked={Boolean(selections.functionality)}
          onChange={(checked) => onToggleClick('functionality', checked)}
          loading={loading}
        />
        <Html.span testId={testId.functionalityInfoboxToggle} onClick={() => toggleInfoBox(InfoBox.Functionality)}>
          <Icon
            className={[styles.preferenceToggle, selectedInfoBox === InfoBox.Functionality && styles.selected]}
            name={IconList.caretDown}
          />
        </Html.span>
      </Html.div>
      <Html.div
        className={[styles.infoBox, selectedInfoBox !== InfoBox.Functionality && 'd-none', 'mt-2 p-2']}
        typography="caption"
      >
        {formatMessage(messages.pages.layout.cookieConsentDialog.functionalityInfo)}
      </Html.div>
      <Button testId={testId.confirmButton} className="w-100 mt-7" onClick={onConfirmClick} loading={loading}>
        {formatMessage(messages.pages.layout.cookieConsentDialog.confirm)}
      </Button>
    </WrapperComponent>
  );
};

export type { DialogElement };
export { Dialog };
export default Dialog;
