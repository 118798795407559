import parse from 'utils/parse';

const makeTestId = (...ids: string[]): string => parse.toTestId('cookie-consent-dialog', ...ids);

const testId = Object.freeze({
  dialog: makeTestId(),
  policyLink: makeTestId('policy-link'),
  essentialInfoboxToggle: makeTestId('essential-infobox-toggle'),
  analyticalToggle: makeTestId('analytical-toggle'),
  analyticalInfoboxToggle: makeTestId('analytical-infobox-toggle'),
  functionalityToggle: makeTestId('functionality-toggle'),
  functionalityInfoboxToggle: makeTestId('functionality-infobox-toggle'),
  confirmButton: makeTestId('confirm-button'),
});

export { testId };
