import React from 'react';
import Html from 'components/html';
import application from 'services/application';
import useReadonlyObservable from 'enhancers/use-readonly-observable';

import { testId } from './activity-indicator.settings';
import styles from './activity-indicator.module.scss';

const ActivityIndicator: React.FunctionComponent<unknown> = () => {
  const [loading] = useReadonlyObservable(application.onLoad$, application.loading);

  if (!loading) {
    return null;
  }

  return (
    <Html.div className={styles.activity} testId={testId.component}>
      <Html.div className={styles.stripe} />
      <Html.div className={[styles.stripe, styles.followUp]} />
    </Html.div>
  );
};

export default ActivityIndicator;
