import { defineMessages } from 'react-intl';

export default {
  components: {
    cookieConsentBanner: {
      ...defineMessages({
        title: {
          id: 'components.cookieConsentBanner.title',
          defaultMessage: 'We Respect Your Privacy',
        },
        content: {
          id: 'components.cookieConsentBanner.content',
          defaultMessage:
            'We use cookies and similar technologies, including third-party cookies, to improve your experience, analyze traffic and user activity, and for promotion purposes. To learn about our cookies, please read our <a>Cookie Policy</a>.',
        },
        accept: {
          id: 'components.cookieConsentBanner.accept',
          defaultMessage: 'Accept Cookies',
        },
        reject: {
          id: 'components.cookieConsentBanner.reject',
          defaultMessage: 'Reject Cookies',
        },
        manage: {
          id: 'components.cookieConsentBanner.manage',
          defaultMessage: 'Manage Cookies',
        },
      }),
    },
  },
};
